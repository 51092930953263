import React, { useEffect, useState } from 'react'
import * as THREE from 'three'
import { Canvas } from '@react-three/fiber'
import {
  AccumulativeShadows,
  Box,
  Center,
  Environment,
  OrbitControls,
  RandomizedLight,
  // useTexture,
  // Reflector,
  Text,
  useGLTF,
  useVideoTexture
} from '@react-three/drei'
import Mpegts from 'mpegts.js'
import './Junost.scss';

const junostRed = '#c12028'
const junostBlack = '#151514'

const textureFrequenzlabel = new THREE.TextureLoader().load(require('./assets/images/frequenz-band.jpg'));
textureFrequenzlabel.repeat.set(1.3, 1.1);
textureFrequenzlabel.offset.set(-0.45, -0.15);

const materialFrequenz = new THREE.MeshStandardMaterial({
  map: textureFrequenzlabel,
  side: 2
})

const textureBacklabelNormals = new THREE.TextureLoader().load(require('./assets/images/back-label-normals.jpg'));
textureBacklabelNormals.rotation = Math.PI / 2;
textureBacklabelNormals.center = new THREE.Vector2(0.5, 0.5); // center of texture.
textureBacklabelNormals.repeat.set(0.9, 1.35);
textureBacklabelNormals.offset.set(0, -0.3);

Mpegts.LoggingControl.applyConfig({
  enableAll: false
});

const vid = document.createElement("video");
vid.src = require('./assets/videos/schnee.mp4'); // 
vid.loop = true;
vid.muted = true;
vid.volume = 0;
vid.play();
vid.playsInline = true;
vid.id = "video";

var curSlider = 0;
var vPlayer;

// https://codesandbox.io/s/drei-reflector-bfplr
// UV MApping https://all3dp.com/2/blender-uv-mapping-simply-explained/
// npx gltfjsx junost-einzeln.gltf --transform --shadows
// ffmpeg -nostdin -loglevel error -re -stream_loop -1 -i ../../24hvideo.mp4 -f flv -c:v copy -c:a copy -maxrate 400k -bufsize 1835k -pix_fmt yuv420p -flags -global_header -hls_time 10 -hls_list_size 6 -hls_wrap 10 -start_number 1 rtmp://localhost/live/channel1 2> /dev/null &
// ffmpeg -nostdin -loglevel error -re -stream_loop -1 -i ./doc/source.flv -f flv -c:v copy -c:a copy -maxrate 400k -bufsize 1835k -pix_fmt yuv420p -flags -global_header -hls_time 10 -hls_list_size 6 -hls_wrap 10 -start_number 1 rtmp://localhost/live/channel2 2> /dev/null &


function JunostTV(props) {
  console.log('Junost')

  useEffect(() => {
    // console.log('load video')

    if (Mpegts.getFeatureList().mseLivePlayback) {
      vPlayer = Mpegts.createPlayer({
        type: 'mse',  // could also be mpegts, m2ts, flv
        isLive: true,
        autoCleanupSourceBuffer: true,
        muted: true,
        url: 'https://stream.nx20.de/live/channel1.flv'
      });
      vPlayer.attachMediaElement(vid);
      vPlayer.load();
      vPlayer.on(Mpegts.Events.MEDIA_INFO, playChannel)
      // vPlayer.play();
    }
  }, []);




  const { scene } = useGLTF(require('./assets/objects/cube.gltf'), './3d/draco/')
  const { nodes, materials } = useGLTF(require('./assets/objects/junost-einzeln-transformed.glb'), './3d/draco/')

  const myMesh = React.useRef();
  const [active, setActive] = useState(1)
  const [orbitActive, setOrbitActive] = useState(true)

  const [volume, setVolume] = useState(0)

  const materialTransparent = new THREE.MeshPhysicalMaterial({
    color: '#ffff00',
    opacity: 0,
    // transmission: 1,
    transparent: true
  });

  const materialGlass = new THREE.MeshPhysicalMaterial({
    color: '#ffffff',
    opacity: 1,
    metalness: 0.05,
    roughness: 0.05,
    transmission: 1, // Add transparency
    thickness: 1, // Add refraction!
    flatShading: false
  });

  const videoTextureProps = {
    muted: (volume <= 0),
    loop: true,
    volume: volume / 100
  }

  const textureVideoTest = new THREE.TextureLoader().load(require('./assets/images/fubk_analog_testbild.jpg'));

  // const video1 = useVideoTexture(require('./assets/videos/maulwurf.mp4'), videoTextureProps)
  const liveVideo = new THREE.VideoTexture(vid ?? textureVideoTest);
  liveVideo.rotation = Math.PI / -2;
  liveVideo.center = new THREE.Vector2(0.5, 0.5); // center of texture.
  liveVideo.repeat.set(1.04, 1.35);
  liveVideo.offset.set(0, 0.14);

  const liveVideoLoad = useVideoTexture(require('./assets/videos/schnee.mp4'), videoTextureProps)
  liveVideoLoad.rotation = Math.PI / -2;
  liveVideoLoad.center = new THREE.Vector2(0.5, 0.5); // center of texture.
  liveVideoLoad.repeat.set(1.04, 1.35);
  liveVideoLoad.offset.set(0, 0.14);

  // const video2 = useVideoTexture(require('./assets/videos/dsmdm-teil1.mp4'), videoTextureProps)
  // // const video2 = useVideoTexture(vid)
  // video2.rotation = Math.PI / -2;
  // video2.center = new THREE.Vector2(0.5, 0.5); // center of texture.
  // video2.repeat.set(1.04, 1.35);
  // video2.offset.set(0, 0.14);

  const [textureVideo, setTextureVideo] = useState(liveVideo)

  const materialScreenImage = new THREE.MeshStandardMaterial({
    color: '#ffffff',
    map: textureVideo ?? null,
    side: THREE.BackSide,
  });

  const materialMetal = new THREE.MeshPhysicalMaterial({
    color: '#c2c2c2',
    roughness: 0.1,
    transmission: 0, // Add transparency
    thickness: 0.5, // Add refraction!
    metalness: 1
  });

  const materialSilver = new THREE.MeshPhysicalMaterial({
    color: '#ffffff',
    // roughness: 0.1,
    // transmission: 0, // Add transparency
    metalness: 0.5
  });

  const materialLabelBack = new THREE.MeshStandardMaterial({
    color: junostRed,
    normalMap: textureBacklabelNormals,
    // displacementScale: 1,
    side: THREE.BackSide,
    metalness: 0
  })



  const materialPlastikBlack = new THREE.MeshPhysicalMaterial({
    color: junostBlack,
    name: 'PlastikBlack',
    metalness: 0.2,
    side: 2
  })

  const materialPlastikWhite = new THREE.MeshPhysicalMaterial({
    color: '#ffffff',
    metalness: 0.1,
    side: 2
  })

  const materialPlastikRed = new THREE.MeshPhysicalMaterial({
    color: junostRed,
    side: 2,
    metalness: 0.1,
    roughness: 0.1,
    transmission: 0, // Add transparency
    thickness: 0.1, // Add refraction!
  })

  function playChannel() {
    console.log('ok');
    setTextureVideo(liveVideo)
    vPlayer.play();
  }

  function switchChannel(nr) {
    if (vPlayer != null) {
      console.log('reset')
      vPlayer.unload();
      vPlayer.off(Mpegts.Events.MEDIA_INFO, playChannel);
      vPlayer.detachMediaElement();
      vPlayer.destroy();
      vPlayer = null;
    }
    // console.log('switch', `https://stream.nx20.de/live/channel${nr}.flv`)
    vPlayer = Mpegts.createPlayer({
      type: 'mse',  // could also be mpegts, m2ts, flv
      isLive: true,
      autoCleanupSourceBuffer: true,
      muted: true,
      url: `https://stream.nx20.de/live/channel${nr}.flv`
    });
    vPlayer.attachMediaElement(vid);
    vPlayer.load();
    vPlayer.on(Mpegts.Events.MEDIA_INFO, playChannel)
    // vPlayer.play();
  }

  function pressKnob(nr) {
    setActive(nr)
    setTextureVideo(liveVideoLoad)
    switchChannel(nr)
  }

  return <primitive object={scene} {...props}>
    <group {...props} dispose={null} rotation={[Math.PI, 0, 0]}>
      <mesh castShadow receiveShadow geometry={nodes.antenne.geometry} material={materialMetal} rotation={[Math.PI / 2, 0, 0]} />
      <group rotation={[Math.PI / 2, 0, 0]}>
        <mesh castShadow receiveShadow geometry={nodes.antennenKabel_1.geometry} material={materialPlastikBlack} />
        <mesh castShadow receiveShadow geometry={nodes.antennenKabel_2.geometry} material={materialMetal} />
      </group>
      <mesh castShadow receiveShadow geometry={nodes['case'].geometry} material={materialPlastikRed} rotation={[Math.PI / 2, 0, 0]} />
      <mesh castShadow receiveShadow geometry={nodes.frequemzPlexi.geometry} material={materialFrequenz} rotation={[Math.PI / 2, 0, 0]} />
      <group rotation={[Math.PI / 2, 0, 0]}>
        <mesh castShadow receiveShadow geometry={nodes.front_1.geometry} material={materialPlastikBlack} />
        <mesh castShadow receiveShadow geometry={nodes.front_2.geometry} material={materialPlastikWhite} />
      </group>
      <mesh castShadow receiveShadow geometry={nodes.fuesse.geometry} material={materialPlastikRed} rotation={[Math.PI / 2, 0, 0]} />
      <mesh castShadow receiveShadow geometry={nodes.kabel.geometry} material={materialPlastikBlack} rotation={[Math.PI / 2, 0, 0]} />
      <mesh castShadow receiveShadow geometry={nodes.labelBack.geometry} material={materialLabelBack} rotation={[Math.PI / 2, 0, 0]} />
      <group rotation={[Math.PI / 2, 0, 0]}>
        <mesh castShadow receiveShadow geometry={nodes.logo_1.geometry} material={materials.frequenzButtons_gray} />
        <mesh castShadow receiveShadow geometry={nodes.logo_2.geometry} material={materials.frequenzButtons_white} />
      </group>
      <group rotation={[Math.PI / 2, 0, 0]}>
        <mesh castShadow receiveShadow geometry={nodes.plastikBack_1.geometry} material={materialSilver} />
        <mesh castShadow receiveShadow geometry={nodes.plastikBack_2.geometry} material={materialSilver} />
        <mesh castShadow receiveShadow geometry={nodes.plastikBack_3.geometry} material={materialPlastikBlack} />
        <mesh castShadow receiveShadow geometry={nodes.plastikBack_4.geometry} material={materialSilver} />
      </group>
      <group rotation={[Math.PI / 2, 0, 0]}>
        <mesh castShadow receiveShadow geometry={nodes.plug_1.geometry} material={materialPlastikBlack} />
        <mesh castShadow receiveShadow geometry={nodes.plug_2.geometry} material={materialMetal} />
      </group>
      <mesh castShadow receiveShadow geometry={nodes.schrauben.geometry} material={materialMetal} rotation={[Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes.screen.geometry} material={materialGlass} rotation={[Math.PI / 2, 0, 0]} />
      <group rotation={[Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.screenPro_1.geometry} material={materialPlastikBlack} />
        <mesh geometry={nodes.screenPro_2.geometry} material={materialScreenImage} />
      </group>
      <mesh castShadow receiveShadow geometry={nodes.socket.geometry} material={materialPlastikBlack} rotation={[Math.PI / 2, 0, 0]} />
      {/* <PresentationControls
        config={{ mass: 3, tension: 250 }}
        snap={false}
        rotation={[0, 0, 0]}
        polar={[-Math.PI / 3, Math.PI / 3]}
        azimuth={[-Math.PI / 1.4, Math.PI / 2]}
        cursor={true}
      > */}
      {/* <CameraControls
        onChange={(event) => {
          console.log('onChange', event)
        }}
      > */}
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.steeringWheels.geometry}
        material={materialPlastikBlack}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <Box
        args={[10, 50, 30]}                // Args for the buffer geometry
        position={[35, -40, 0]}
        material={materialTransparent}
        onPointerDown={(event) => {
          setOrbitActive(false)
          curSlider = event.clientY
          // console.log('onPointerDown 2', materialScreenImage, nodes.screenPro_2.geometry)
        }}
        onPointerMove={(event) => {
          // setOrbitActive(false)
          if (!orbitActive) {
            curSlider -= event.clientY
            // console.log('onPointerMove 2', curSlider / 1000, event.movementY, volume)
            setVolume(vol => (vol - event.movementY >= 0 && vol - event.movementY <= 100) ? vol - event.movementY : vol)
            vid.volume = volume / 100
            vid.muted = (volume <= 0)
          }
        }}
        onPointerLeave={(event) => {
          setTimeout(function () { setOrbitActive(true) }, 200);

          // console.log('onPointerLeave 2', event)
        }}
        onPointerUp={(event) => {
          setOrbitActive(true)
          // console.log('onPointerUp 2', event)
        }}
      />
      {/* </CameraControls> */}
      {/* </PresentationControls> */}
      <mesh castShadow receiveShadow geometry={nodes.symboleTop.geometry} material={materialPlastikRed} rotation={[Math.PI / 2, 0, 0]} />

      <group rotation={[Math.PI / 2, 0, 0]} position={[0, 0, active === 1 ? 1.5 : 0]} onClick={() => {
        pressKnob(1)
        // setActive(1)
        // switchChannel(1)
        // setTextureVideo(video1)
      }} ref={myMesh}>
        <mesh castShadow receiveShadow geometry={nodes['frequenzButton-1_1'].geometry} material={materials.front_gray} />
        <mesh castShadow receiveShadow geometry={nodes['frequenzButton-1_2'].geometry} material={materials.front_white} />
      </group>
      <group rotation={[Math.PI / 2, 0, 0]} position={[0, 0, active === 2 ? 1.5 : 0]} onClick={() => {
        pressKnob(2)
        // setActive(2)
        // setTextureVideo(liveVideoLoad)
        // switchChannel(2)
      }}>
        <mesh castShadow receiveShadow geometry={nodes['frequenzButton-2_1'].geometry} material={materials.front_gray} />
        <mesh castShadow receiveShadow geometry={nodes['frequenzButton-2_2'].geometry} material={materials.front_white} />

      </group>
      <group rotation={[Math.PI / 2, 0, 0]} position={[0, 0, active === 3 ? 1.5 : 0]} onClick={() => {
        pressKnob(3)
        // setActive(3)
        // switchChannel(3)
      }}>
        <mesh castShadow receiveShadow geometry={nodes['frequenzButton-3_1'].geometry} material={materials.front_gray} />
        <mesh castShadow receiveShadow geometry={nodes['frequenzButton-3_2'].geometry} material={materials.front_white} />
      </group>
      <group rotation={[Math.PI / 2, 0, 0]} position={[0, 0, active === 4 ? 1.5 : 0]} onClick={() => {
        pressKnob(4)
        // setActive(4)
        // switchChannel(4)
      }}>
        <mesh castShadow receiveShadow geometry={nodes['frequenzButton-4_1'].geometry} material={materials.front_gray} />
        <mesh castShadow receiveShadow geometry={nodes['frequenzButton-4_2'].geometry} material={materials.front_white} />
      </group>
      <Text color="white" scale={10} rotation={[-Math.PI - 0.1, 0, 0]} position={[-45, -20, -73]}>
        {orbitActive ? '' : volume}
      </Text>
    </group>
    <OrbitControls
      enabled={orbitActive}
      maxPolarAngle={Math.PI / 2.1}
      enablePan={false}
      minDistance={4}
      maxDistance={20}
      zoomSpeed={1}
    />
  </primitive>
}

/* function Ground() {
  const [floor, normal] = useTexture([require('./assets/images/SurfaceImperfections003_1K_var1.jpg'), require('./assets/images/SurfaceImperfections003_1K_Normal.jpg')])
  return (
    <Reflector blur={[400, 100]} resolution={512} args={[10, 10]} mirror={0.5} mixBlur={6} mixStrength={1.5} position={[0, -0.3, 0]} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
      {(Material, props) => <Material color="#a0a0a0" metalness={0.4} roughnessMap={floor} normalMap={normal} normalScale={[2, 2]} {...props} />}
    </Reflector>
  )
} */

function Junost() {
  return (
    <div className="junost">
      {/* <video id="video"
        src="http://192.168.20.120:8080/live/livestrea.flv" controls={false} autoPlay muted={true}>
      </video> */}
      {/* <div ref={(nodeElement) => { nodeElement && nodeElement.appendChild(vid) }} /> */}
      <Canvas shadows camera={{ position: [8, 1.5, 8], fov: 25 }}>
        <ambientLight intensity={0.1} />
        <directionalLight color="#ffffff" position={[10, 0, 5]} shadow-mapSize={[1024, 1024]} />
        <group position={[0, -0.5, 0]}>
          <Center top position={[0, 0, 0]}>
            <mesh castShadow>
              <JunostTV rotation={[0, Math.PI / 9, 0]} scale={0.08} />
            </mesh>
          </Center>
          <AccumulativeShadows temporal frames={100} color={'#28819e'} colorBlend={2} toneMapped={true} alphaTest={0.9} opacity={2} scale={12}>
            <RandomizedLight amount={8} radius={4} ambient={0.5} intensity={1} position={[5, 5, -10]} bias={0.001} />
          </AccumulativeShadows>
          {/* <Ground /> */}
        </group>
        <Environment
          files={require('./assets/images/anniversary_lounge_2k.hdr')}
          blur={0.5}
        />
      </Canvas>
    </div>
  );
}

export default Junost;
