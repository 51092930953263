import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import Junost from './Junost';
import Hotzpotz from './Hotzpotz';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <div className='main'>
      <Hotzpotz />
      <Junost />
    </div>
  </React.StrictMode>
);
