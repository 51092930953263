import React, { useEffect, useState } from 'react';
import './Hotzpotz.scss';
import hotzpotz from './assets/images/hotzpotz.svg'

function Hotzpotz() {

    const [scale, setScale] = useState(1);

    useEffect(() => {
        const onScroll = (event) => {

            let scaleTmp = scale + (event.deltaY < 0 ? 0.1 : -0.1) // event.deltaY * -0.001; // 
            scaleTmp = Math.min(Math.max(.125, scaleTmp), 4)

            if (scaleTmp < 1) {
                scaleTmp = 1
            }
            if (scaleTmp > 3) {
                scaleTmp = 3
            }

            setScale(scaleTmp)
        }
        // clean up code
        window.removeEventListener('wheel', onScroll);
        window.addEventListener('wheel', onScroll, { passive: true });
        return () => window.removeEventListener('wheel', onScroll);
    }, [scale]);

    return (
        <div className='hotzpotz' style={{ transform: `scale(${scale}) translate(0, -${scale * 22}px)` }}>
            <img src={hotzpotz} width="150" alt='hotzpotz.' />
        </div>
    )
};

export default Hotzpotz;